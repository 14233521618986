import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { useControlledState } from "@react-stately/utils";
import { Navigate } from "@tanstack/react-router";
import type { AccessGroupCreateBody, ApiTokenCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { findById } from "@warrenio/utils/collections/findById";
import type { Subset } from "@warrenio/utils/types/Subset";
import { useEffect, type ReactNode } from "react";
import { Input } from "react-aria-components";
import { Controller, useForm, useFormContext, type SubmitHandler } from "react-hook-form";
import { pick } from "remeda";
import { proxy, snapshot, subscribe } from "valtio";
import { EditableStringList } from "../../components/EditableList.tsx";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { Separator } from "../../components/Separator.tsx";
import { SiteName } from "../../components/SiteName.tsx";
import { CreateFormAction } from "../../components/forms/CreateFormAction.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { IconRadio, type IconRadioContentProps } from "../../components/forms/IconRadio.tsx";
import { WHookForm, type FormWithDefaultsProps } from "../../components/forms/WHookForm.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { useAriaField } from "../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../components/forms/requiredMessage.ts";
import { cn } from "../../utils/classNames.ts";
import { useTrigger } from "../../utils/react/eventTrigger.ts";
import { useOnce } from "../../utils/react/useOnce.ts";
import type { ResourceType } from "../api/resourceTypes.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { BillingAccountField } from "../billing/forms/BillingAccountField.tsx";
import { createFullAccessDelegationMutation } from "./delegation/apiOperations.ts";
import { delegationLink, tokenLink } from "./links.ts";
import { scopeLabel } from "./scopeLabel.tsx";
import { ApiDocLink } from "./token/ApiDocLink.tsx";
import { createApiTokenMutation } from "./token/apiOperations.ts";

interface ItemType extends IconRadioContentProps {
    id: string;
    description: ReactNode;
}

const types = [
    {
        id: "api_token",
        title: "API Token",
        icon: "jp-swap-icon",
        description: (
            <>
                Creating an access by API Token gives you a key to use with our <SiteName /> API.
            </>
        ),
    },
    {
        id: "access_delegation",
        title: "Delegate Access",
        icon: "jp-user-group-icon",
        description: (
            <>
                Delegating access to a chosen trusted user enables the user to act as you and perform all operations on
                behalf of your <SiteName /> account.
            </>
        ),
    },
] as const satisfies ItemType[];

const scopes = [
    {
        id: "restricted",
        title: scopeLabel(true),
        icon: "jp-card-icon",
        description: (
            <>By selecting Restricted, access will be limited to resources belonging to the selected billing account.</>
        ),
    },
    {
        id: "global",
        title: scopeLabel(false),
        icon: "jp-web-icon",
        description: (
            <>
                By selecting Global, all resources under this <SiteName /> account can be accessed. Exercise caution
                when selecting Global scope.
            </>
        ),
    },
] as const satisfies ItemType[];

type Scope = "restricted" | "global";

export type AccessFormTypeId = Subset<"api_token" | "access_delegation", ResourceType>;

/** Top-level form state */
export interface AccessFormState {
    /** Stores common input values that remain the same when switching between types */
    common?: Partial<CommonInputs>;
    token?: Partial<ApiTokenInputs>;
    delegation?: Partial<AccessGroupInputs>;
}

/** Inputs shared between tokens & delegation */
interface CommonInputs {
    description: string;
    scope: Scope;
    billing_account_id: number | undefined;
}

interface ApiTokenInputs extends CommonInputs {}

interface AccessGroupInputs extends CommonInputs {
    emails: string[];
    /** Current value of the e-mail adding textbox. Used only for validation. */
    emailInput: string;
}

/** Extract common input values shared between {@link ApiTokenInputs} and {@link AccessGroupInputs} from an inputs object */
function pickCommonInputs<T extends CommonInputs>(inputs: Partial<T>): Partial<CommonInputs> {
    return pick(inputs, ["description", "scope", "billing_account_id"]);
}

export interface AccessCreateProps extends FormWithDefaultsProps<AccessFormState> {
    typeId?: AccessFormTypeId;
    onChangeTypeId?: (id: AccessFormTypeId) => void;
}

function AccessForm({ defaultValues, onSave, typeId: controlledTypeId, onChangeTypeId }: AccessCreateProps) {
    const [typeId, setTypeId] = useControlledState(controlledTypeId, types[0].id, onChangeTypeId);

    // Bundle everything up into a single state object so:
    //  - the parent component can save it all together
    //  - the form components can modify it efficiently (without re-renders)
    const state = useOnce(() =>
        proxy<AccessFormState>({
            common: {},
            ...defaultValues,
        }),
    );

    // Notify the parent component when the form state changes
    useEffect(() => {
        return subscribe(state, (_op) => {
            // NB: Needs a type cast since `snapshot` returns a read-only object, which is inconvenient to use in our prop types
            onSave?.(snapshot(state) as typeof state);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const type = findById(types, typeId);

    function setCommonInputs(inputs?: CommonInputs) {
        state.common = inputs ? pickCommonInputs(inputs) : undefined;
    }

    return (
        <>
            <WRadioGroup label="Type" isRequired value={typeId} onChange={setTypeId} description={type.description}>
                {types.map((item) => (
                    <IconRadio key={item.id} {...item} />
                ))}
            </WRadioGroup>

            <Separator />

            {typeId === "api_token" ? (
                <ApiTokenForm
                    // NB: Not using snapshot of `state` since `defaultValues` is only read on initial render
                    defaultValues={{ ...state.token, ...state.common }}
                    onSave={(inputs) => {
                        console.debug("ApiTokenForm.onSave: %o", inputs);
                        state.token = inputs;
                        setCommonInputs(inputs);
                    }}
                />
            ) : (
                <AccessDelegationForm
                    defaultValues={{ ...state.delegation, ...state.common }}
                    onSave={(inputs) => {
                        console.debug("AccessDelegationForm.onSave: %o", inputs);
                        state.delegation = inputs;
                        setCommonInputs(inputs);
                    }}
                />
            )}
        </>
    );
}

function AccessScopeField() {
    const { watch, control } = useFormContext<CommonInputs>();
    const scope = findById(scopes, watch("scope"));
    return (
        <>
            <Controller
                control={control}
                name="scope"
                render={({ field }) => (
                    <WRadioGroup
                        label="Scope"
                        isRequired
                        description={scope.description}
                        isDisabled={field.disabled}
                        value={field.value}
                        onChange={field.onChange}
                    >
                        {scopes.map((item) => (
                            <IconRadio key={item.id} {...item} />
                        ))}
                    </WRadioGroup>
                )}
            />

            {watch("scope") === "restricted" && (
                <BillingAccountField
                    resourceType="api_token"
                    allowClosedAccounts
                    isRequired
                    description="Choose a billing account you wish to share access to"
                />
            )}
        </>
    );
}

function AccessNameField() {
    const form = useFormContext<CommonInputs>();

    const { ref, props } = useAriaField("description", form, { required: requiredMessage });
    return (
        <WTextField {...props} isRequired label="Access name">
            <Input ref={ref} type="text" className={cn(FF.FormFieldInput, TF.Input)} />
        </WTextField>
    );
}

function AccessDelegationForm({ defaultValues, ...formProps }: FormWithDefaultsProps<AccessGroupInputs>) {
    const accessGroupMutation = useStandardMutation(createFullAccessDelegationMutation);
    const form = useForm<AccessGroupInputs>({
        disabled: accessGroupMutation.isPending,
        defaultValues: {
            scope: "restricted",
            emails: [],
            ...defaultValues,
        } as const,
    });
    const [validationTrigger, triggerValidation] = useTrigger();

    const { setValue, clearErrors, control } = form;

    const onSubmit: SubmitHandler<AccessGroupInputs> = async (inputs) => {
        const { scope, description, billing_account_id, emails } = inputs;

        const body: AccessGroupCreateBody = {
            ...(scope === "restricted" && { billing_account_id }),
            name: description,
        };

        await accessGroupMutation.mutateAsync({ body, emails });
    };

    if (accessGroupMutation.isSuccess) {
        return <Navigate {...delegationLink(accessGroupMutation.data.result)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit} onInvalid={triggerValidation} {...formProps}>
            <AccessScopeField />
            <Separator />
            <FormField
                isRequired
                label="Delegate access to"
                description={
                    <>
                        Insert email of the <SiteName /> user you want to delegate access to. The user will need to
                        accept the access invitation from their profile view.
                    </>
                }
            >
                <Controller
                    control={control}
                    name="emails"
                    rules={{
                        // NB: must set `deps` to trigger validation when `emailInput` changes
                        deps: ["emailInput"],

                        validate: (v, { emailInput }) => {
                            if (v.length === 0) {
                                return "At least one email is required";
                            }
                            // Check if the user has typed something in the input but not added it to the list
                            if (emailInput) {
                                return "Please finish adding the email";
                            }
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <EditableStringList
                            items={field.value}
                            onChange={field.onChange}
                            onInputChange={(e) => {
                                setValue("emailInput", e);
                                // Clear the errors when the user starts doing something (they will be re-validated on submit)
                                clearErrors("emails");
                            }}
                            // Pass a ref so we can focus the input when submit is pressed and the field is invalid
                            inputRef={field.ref}
                            errorMessage={error?.message}
                            //TODO: see validationTrigger vist on lahenduseks sellele https://warrenio.atlassian.net/browse/WRN-117 punktile 3?
                            validationTrigger={validationTrigger}
                        />
                    )}
                />
            </FormField>
            <AccessNameField />
            <Separator />
            <CreateFormAction resourceType="access_delegation" />
        </WHookForm>
    );
}

function ApiTokenForm({ defaultValues, ...formProps }: FormWithDefaultsProps<ApiTokenInputs>) {
    const apiTokenMutation = useStandardMutation(createApiTokenMutation);
    const form = useForm<ApiTokenInputs>({
        disabled: apiTokenMutation.isPending,
        defaultValues: {
            scope: "restricted",
            ...defaultValues,
        },
    });

    // const { getValues, formState } = form;
    // logExpandedObject("ApiTokenForm", { defaultValues, v: getValues(), formState });

    const onSubmit: SubmitHandler<ApiTokenInputs> = async (inputs) => {
        const { scope, description, billing_account_id } = inputs;

        const body: ApiTokenCreateBody = {
            ...(scope === "restricted" && { billing_account_id }),
            description,
            restricted: scope === "restricted",
        };

        await apiTokenMutation.mutateAsync({ body });
    };

    if (apiTokenMutation.isSuccess) {
        return <Navigate {...tokenLink(apiTokenMutation.data)} />;
    }

    return (
        <WHookForm form={form} onSubmit={onSubmit} {...formProps}>
            <AccessScopeField />
            <Separator />
            <AccessNameField />
            <Separator />
            <CreateFormAction resourceType="api_token" />
        </WHookForm>
    );
}

export function AccessCreate(props: AccessCreateProps) {
    return (
        <>
            <CreateHero />
            <AccessForm {...props} />
        </>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Access" icon="jp-access-icon">
            <p className="text-muted">
                Enable other users or systems to perform actions with resources connected to this <SiteName /> account.
            </p>
            <ul className="text-muted m-0 p-0 pl-4">
                <li>
                    Generate an API Access Token to use our <SiteName /> <ApiDocLink>API</ApiDocLink>.
                </li>
                <li>Delegate access to your account to a trusted user.</li>
            </ul>
        </HeroBlock>
    );
}
